import Select from 'react-select';
import { useState ,useRef} from 'react';

import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Card,
} from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import Rating from "@mui/material/Rating";
import { useEffect } from 'react';
import { makePostRequest, makeGetRequest,makePostRequestForAll,BASE_URL,isProduction,secretKey} from "../../../service/api";
import { URLS } from "../../../constants/urls";
import { getUserDetailsFromToken , isValidFileUploaded , checkUrl , toastifyMessage} from '../../../service/auth'
// import ChildCard from "./ChildCard";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
const filter = createFilterOptions();
// import PdfViewer from "./PdfViewer";
import moment from "moment";
import axios from 'axios';
import CryptoJS from "crypto-js";
import { ThreeDots } from "react-loader-spinner";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import CKEditor from "react-ckeditor-component";
import { toast } from 'react-toastify';
import JoditEditor from 'jodit-react';
// import ImgCrop from "antd-img-crop";
// import { Upload, Button } from "antd";
// import { PlusOutlined } from "@ant-design/icons";


// /*
//      * Parentregistration  function is used to register user
//      *
//      * @author Naresh Kumar <naresh.gulati@webappmate.com>
//      * created on: 15-4-2023
//      * /

const EditCustomer = () => {

  let history = useHistory();
  let my_token = sessionStorage.getItem("auth-token");
  let useractive = sessionStorage.getItem("useractive");
  const headers = { Authorization: "Bearer " + my_token };
  const fref = useRef();
  const lref = useRef();
  const scref = useRef();
  const hcref = useRef();
  const ceref = useRef();
  const ajref = useRef();
  const fbaref = useRef();
  const imgref = useRef();
  const childref = useRef();
  const childrel = useRef();
  const emref = useRef();
  const phref = useRef();
  const zpref = useRef();
  const userRef = useRef();
 
  const urlSearchParams = new URLSearchParams(window.location.search);
  const queryParams = Object.fromEntries(urlSearchParams.entries());
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [Firth, setFirth] = useState("Yes");
  const [selectchild, setselectchild] = useState("");
  const [selectfb, setSelectfb] = useState("");
  const [selectce, setSelectce] = useState("");
  const [selectaj, setSelectaj] = useState("");
  const [selectfa, setSelectfa] = useState("");
  const [selectffb, setSelectffb] = useState("");
  const [showaddperson, setshowaddperson] = useState(true);
  const [phoneerror, setphoneerror] = useState(false);
  const [emailerror, setemailerror] = useState(false);
  const [ziperror, setziperror] = useState(false);
  const [error, seterror] = useState(false);
  const [file, setFile] = useState("");
  const [childList, setChildList] = useState([]);
  const [userType, setUserType] = useState("");
  const [homeschool, sethomeschool] = useState([]);
  const [imgflag, setimgflag] = useState(false);
  const [inputlist, setinputlist] = useState([]);
  const [childlen, setChildlen] = useState(0);
  const [zipcode, setZipcode] = useState("");
  const [status, setStatus] = useState(false);
  const [selectTimeZone, setSelectTimeZone] = useState("")
  const [Bio, setBio] = useState("");
  const [userTypeData, setUserTypeData] = useState([]);
  const [user, setUser] = useState([]);
 
  const state = location.state;
  var childdisplay = [
    "First",
    "Second",
    "Third",
    "Fourth",
    "Fifth",
    "Sixth",
    "Seventh",
    "Eighth",
    "Ninth",
    "Tenth",
    "Eleventh",
    "Twelveth",
    "Thirteenth",
    "Fourteenth",
    "Fifteenth",
    "Sixteenth",
    "Seventeenth",
    "Eighteenth",
    "Nineteenth",
    "Twentieth",
    "Twenty first",
    "Twenty second",
    "Twenty third",
    "Twenty fourth",
    "Twenty fifth",
    "Twenty sixth",
    "Twenty seventh",
    "Twenty eighth",
    "Twenty ninth",
    "Thirtieth",
    "Thirty first",
    "Thirty second",
    "Thirty third",
    "Thirty fourth",
    "Thirty fifth",
    "Thirty sixth",
    "Thirty seventh",
    "Thirty eighth",
    "Thirty ninth",
    "Fourtieth",
    "Fourty first",
    "Fourty second",
    "Fourty third",
    "Fourty fourth",
    "Fourty fifth",
    "Fourty sixth",
    "Fourty seventh",
    "Fourty eighth",
    "Fourty ninth",
    "Fiftieth",
    "Fifty first",
    "Fifty second",
    "Fifty third",
    "Fifty fourth",
    "Fifty fifth",
    "Fifty sixth",
    "Fifty seventh",
    "Fifty eighth",
    "Fifty ninth",
    "Sixtieth",
    "Sixty first",
    "Sixty second",
    "Sixty third",
    "Sixty fourth",
    "Sixty fifth",
    "Sixty sixth",
    "Sixty seventh",
    "Sixty eighth",
    "Sixty ninth",
    "Seventieth",
    "Seventy first",
    "Seventy second",
    "Seventy third",
    "Seventy fourth",
    "Seventy fifth",
    "Seventy sixth",
    "Seventy seventh",
    "Seventy eighth",
    "Seventy ninth",
    "Eightieth",
    "Eighty first",
    "Eighty second",
    "Eighty third",
    "Eighty fourth",
    "Eighty fifth",
    "Eighty sixth",
    "Eighty seventh",
    "Eighty eighth",
    "Eighty ninth",
    "Ninetieth",
    "Ninety first",
    "Ninety second",
    "Ninety third",
    "Ninety fourth",
    "Ninety fifth",
    "Ninety sixth",
    "Ninety seventh",
    "Ninety eighth",
    "Ninety ninth",
    "Hundredth",
  ];

  const [address, setAddress] = useState("");
  const [about, setAbout] = useState("");
  const [locationState, setLocationState] = useState({
    country: "",
    state: "",
    city: "",
    zipcode: "",
    latitude: "",
    longitude: "",
  });
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const [timeZone, setTimeZone] = useState([]);
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  let { id } = useParams();

  //===Functions for Image crop===//

  const [fileList, setFileList] = useState([
    {
      uid: "-1",
      url: "",
    },
  ]);
  const onChange = async ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const beforeCrop = (file) => {
    if (
      file.name.includes("jpg") ||
      file.name.includes("png") ||
      file.name.includes("jpeg") ||
      file.name.includes("gif")
    ) {
      setimgflag(false);
      return true;
    } else {
      setimgflag(true);
      return false;
    }
  };

  const onRemove = async () => {
    setimgflag(false);
  };

  //function for fetching user detail

  const handleshowupload = () => {
    setLoading(true);
    let data = {
      id: id,
    };

    makePostRequest(URLS.CUSTOMER_GET_ONE, null, data, null).then(
      (response) => {
        console.log("response::::::::::::::::::", response);
        setLoading(false);


    // makePostRequest(URLS.userfetchdata, null, data, null).then((response) => {
    //   console.log("response::::::::::::::::::", response);
    //   setLoading(false);
    //   if (response.data.uesrData.firstName != null) {
    //     setStatus(true);
    //   }
      setSelectTimeZone(response?.data?.uesrData?.timezone)
      setfirstname(
        response.data.uesrData.firstName == null
          ? ""
          : response.data.uesrData.firstName
      );
      setlastname(
        response.data.uesrData.lastName == null
          ? ""
          : response.data.uesrData.lastName
      );
      setBio(
        response.data.uesrData.bio_about == null
          ? ""
          : response.data.uesrData.bio_about
      );
      const result = response?.data?.uesrData?.tbl_users_roles?.map(item => ({
        value: item?.tbl_user_role?.id,
        label: item?.tbl_user_role?.role_name
      }));
      setUserTypeData(result);
      setUserType(result.map(val => val.value));
      response.data.uesrData.faith_background == null
        ? setFirth("Yes")
        : setFirth(response.data.uesrData.faith_background);
      response.data.uesrData.faith_affiliation &&
        setSelectfa(response.data.uesrData.faith_affiliation);
      setSelectffb(
        response.data.uesrData.family_faith_background == null
          ? ""
          : response.data.uesrData.family_faith_background
      );
      setSelectce(
        response.data.uesrData.child_education_character_value == null
          ? ""
          : response.data.uesrData.child_education_character_value
      );
      setSelectaj(
        response.data.uesrData.any_additional_job == null
          ? ""
          : response.data.uesrData.any_additional_job
      );
      setChildList(
        response.data.childList == null ? [] : response.data.childList
      );
      setselectchild(
        response.data.uesrData.number_of_child == null ||  response.data.uesrData.number_of_child == 0
          ? ""
          : response.data.uesrData.number_of_child
      );
      
    //   setAbout(
    //     response.data.uesrData.bio_about == null
    //       ? ""
    //       : response.data.uesrData.bio_about
    //   );
      setSelectfb(
        response.data.uesrData.family_background == null
          ? ""
          : response.data.uesrData.family_background
      );
      setChildlen(response.data.uesrData.number_of_child == null ||  response.data.uesrData.number_of_child == 0
        ? ""
        : response.data.uesrData.number_of_child);
    //   setFileList([{ url: uimage + response.data.uesrData.photo }]);

    //   //setimgflag(true)

      const hs = response.data.uesrData.tbl_ideal_homeschools.map((item) => {
        return item.name;
      });
      sethomeschool(hs);
    //   if (file.includes("undefined") || file.includes("null")) {
    //     setFile("");
    //   }
      setAddress(response?.data?.uesrData?.address);
      let addressObj = {
        country: response?.data?.uesrData?.country,
        city: response?.data?.uesrData?.city,
        zipcode: response?.data?.uesrData?.zipcode,
        state: response?.data?.uesrData?.state,
        latitude: response?.data?.uesrData?.latitude,
        longitude: response?.data?.uesrData?.longitude,
      };
      setLocationState(addressObj);
      setZipcode(response?.data?.uesrData?.zipcode);
      setEmail(response?.data?.uesrData?.email);
      setPhone(response?.data?.uesrData?.phone);
    });
  };

  function setchildno() {
    if (childlen > 0) {
      const values = [];

      for (var i = 0; i < childlen; i++) {
        values.push({
          id: childList[i].id,
          firstName: childList[i].firstName,
          lastName: childList[i].lastName,
          flag: true,
        });
      }
      setinputlist(values);
    }
  }
  console.log(inputlist)
  console.log("user type",userTypeData)

  //===Function for uploading  user image===//

  function uploadimage(cropimage) {
    // const formData = new FormData();

    // formData.append("photo", cropimage ? cropimage : "");
    // formData.append("id", userData.userAuth.id);
    // makeFormDateRequset(URLS.useruploadprofile, formData).then((response) => {
    //   console.log("profile upload", response);
    //   if (response.code === 200) {
    //     toastifyMessage(response.message, "success");
    //     setTimeout(() => { }, 6000);
    //   }
    // });
  }


  // const onPreview = async () => {
  //   setFileList([])
  // }
  // function handleChange(e) {

  //   checkfile = (e.target.files);
  //   if (checkfile[0]) {

  //     const fileSize = e.target.files[0].size / (1024 * 1024);
  //     if (fileSize > 2) {
  //       toast.error(
  //         "File size exceeded, Please upload file upto 2 mb")
  //     }
  //     else if (checkfile[0].name.includes("png") || checkfile[0].name.includes("jpg") || checkfile[0].name.includes("jpeg")) {
  //       setFile(URL.createObjectURL(e.target.files[0]));
  //       var file1 = e.target.files[0];
  //       const formData = new FormData();
  //       formData.append("photo", file1)
  //       formData.append("id", tokenPayload.userAuth.id)

  //       axios.post(BASE_URL + URLS.useruploadprofile, formData, { headers })
  //         .then(response => {
  //           if (response.data.code === 200) {
  //             toast.success("Profile image uploaded successfully.");
  //             setTimeout(() => {
  //             }, 2000)

  //           }
  //           else {
  //             toast.error(response.data.message);
  //           }
  //         }).catch((error) => {
  //           console.log("errrr", error)
  //         })
  //     }
  //     else {

  //       toast.error("Please upload only png,jpg,jpeg file");
  //     }
  //   }
  // }

  //== Functions for capturing  user data==//

  const handleInputChange = (index, e) => {
    const values = [...inputlist];
    const updatedValue = e.target.name;
    values[index][updatedValue] = e.target.value;
    setinputlist(values);
  };
  const handlefn = (e) => {
    setfirstname(e.target.value);
  };
  const handleln = (e) => {
    setlastname(e.target.value);
  };
  const handlesc = (e) => {
    setselectchild(e.target.value);
  };

  const handlechild = () => {
    const values = [];
    // console.log("childlen",childlen)
    // console.log("selectchild",selectchild)
    if (childlen <= selectchild) {
      for (var i = 0; i < selectchild; i++) {
        values.push({
          id: childList[i]?.id || null,
          firstName: childList[i]?.firstName || "",
          lastName: childList[i]?.lastName || "",
          flag: true,
        });
      }
   
      // for (var i = childlen; i < selectchild; i++) {
      //   values.push({
      //     firstName: "",
      //     lastName: "",
      //   });
      // }
      setinputlist(values);
    } else {
      toastifyMessage(
        "You cannot reduce the number of students. Please delete the student profile first.",
        "error"
      );
      setselectchild(childlen);
    }
  };

 

  const handlehs = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      sethomeschool([...homeschool, value]);
    } else {
      sethomeschool(homeschool.filter((item) => item !== value));
    }
  };
  const fairthHandle = (e) => {
    setFirth(e.target.value);
  };
  const handlece = (e) => {
    setSelectce(e.target.value);
  };
  const handleaj = (e) => {
    setSelectaj(e.target.value);
  };
  const handlefa = (e) => {
    setSelectfa(e.target.value);
  };
  const handleffb = (e) => {
    setSelectffb(e.target.value);
  };
  const handleform = (event) => {
    event.preventDefault();
  };
  const getUserType = () => {
    makeGetRequest(URLS.GET_USER_TYPE, null, {}, null)
      .then((res) => {
        if (res.code == 200) {
          let option=[];
          res?.data?.length > 0 && res?.data?.map((val)=>{
        option.push({value: val.id, label:val.role_name})
          }) 
          setUserTypeData(option);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // functions for error handling and user data submission//==

  const handlesavedata = () => {
   if (firstname?.length == 0) {
      seterror(true);
      fref.current.focus();
    // } else if (lastname === "") {
    //   seterror(true);
    //   lref.current.focus();
     } 
    else if (zipcode == null) {
      setziperror(true);
      zpref.current.focus();

    } else if (zipcode?.length < 5 || zipcode?.length > 6) {
      setziperror(true);
      zpref.current.focus();
    } else if (userTypeData.length <= 0) {
      seterror(true);
      userRef.current.focus()
    }  
    else if (selectchild < 0) {
      seterror(true);
      scref.current.focus();
    } else if (selectchild > 0 && inputlist?.length == 0) {
      seterror(true);
      childref.current.focus();
    }
    // else if (homeschool?.length == 0) {
    //   seterror(true);
    //   hcref.current.focus();
    // } else if (selectce == "") {
    //   seterror(true);
    //   ceref.current.focus();
    // } else if (selectaj == "" && userType == "2") {
    //   seterror(true);
    //   ajref.current.focus();
    // } 
    else if (selectchild > 0 && inputlist.filter((item) => item.firstName == "")?.length > 0) 
    {
        seterror(true);
        childref.current.focus();
    }
    // else if (selectchild > 0 && inputlist.filter((item) => item.lastName == "")?.length > 0) 
    // {
    //     seterror(true);
    //     childrel.current.focus();
    // }
    else {
      if (!emailValidation()) {
        return;
      }
      if (!phoneNumberValidation()) {
        return;
      }

      // setLoading(true);
      var objecthomeschool = [];
      for (var i = 0; i < homeschool?.length; i++) {
        var obj = { name: homeschool[i] };
        objecthomeschool.push(obj);
      }
      var tempList = [];
      var x = {};
      for (var i = 0; i < inputlist?.length; i++) {
        if (inputlist[i].id != null) {
          x = inputlist[i];
          x.flag = true;
          tempList.push(x);
        } else {
          x = inputlist[i];
          x.flag = false;
          
          tempList.push(x);
        }
      }
      let data = {
        id: id,
        firstName: firstname,
        lastName: lastname,
        number_of_child: selectchild <= 0 ? 0 : selectchild ,
        child_list: tempList,
        ideal_home_schools: objecthomeschool,
        faith_background: Firth,
        family_faith_background: selectffb,
        faith_affiliation: selectfa,
        child_education_character_value: selectce,
        any_additional_job: selectaj,
        address: address,
        timezone: selectTimeZone,
        // city: locationState.city,
        // state: locationState.state,
        user_type_role_id: userTypeData?.map(val => val.value) || [],
        zipcode: zipcode ? zipcode : locationState.zipcode,
        // country: locationState.country,
        bio_about: Bio,
        phone: phone,
        email: email,
      };

      if (locationState.latitude) {
        data.latitude = locationState.latitude;
      }
      if (locationState.longitude) {
        data.longitude = locationState.longitude;
      }

      console.log("data payload",data)
    //   // Api call for user data submission
      makePostRequest(URLS.GET_CUSTOMER_UPDATE, null, data, null)
        .then((response) => {
          console.log("res:::::::::::::::::::",response)
          if (response.code === 200) {
       
            toastifyMessage(response.message, "success");
         
          }
          else
          {
            toastifyMessage(response.message, "error");
          }


    //       var payload = {
    //         resource_id: "",
    //       };
    //       GetAllChild(URLS.getChildList, payload, headers).then((res) => {
    //         dispatch(getchildList(res.data))
    //       });
    //       setLoading(false);
    //       if (selectchild == 0) {
    //         toastifyMessage("User added successfully.", "success");
    //         makeGetRequest(URLS.token, null, { headers }).then((re) => {
    //           let my_token = re?.data?.authorization;
    //           sessionStorage.setItem("auth-token", re?.data?.authorization);
    //           var decoded = my_token ? jwt_decode(my_token) : "";
    //           sessionStorage.setItem("fristname", decoded.userAuth.firstName);
    //         });
    //         setTimeout(() => {
    //           if (state == "paid") {
    //             // navigate("/subscription");
    //             window.location.href = "/subscription";
    //           } else {
    //             // window.location.href = "/search-page";
    //             sessionStorage.setItem("scrollvalue", true)
    //             window.location.href = "/";

    //           }
    //         }, 1000);
    //       } else {
    //         if (response.code === 200) {
    //           toastifyMessage("User added successfully.", "success");
    //           setTimeout(() => {
    //             // if (status == false) {
    //             //   navigate("/child-info/0", { state });
    //             // }
    //             navigate("/child-info/0", { state });
    //           }, 2000);
    //         } else {
    //           toastifyMessage(response.data.message, "error");
    //         }
    //       }
    //     })
    //     .catch((e) => {
    //       console.log(e);
        });
    }
  };
  //useEffect functions//==

  useEffect(() => {
    handlechild();
  }, [selectchild]);
  
  
  useEffect(() => {
    if (selectfb == "no") {
      setshowaddperson(false);
      setSelectfb("no");
      setSelectffb("");
      setSelectfa("");
    } else {
      setshowaddperson(true);
      setSelectfb("Yes");
    }
  }, [selectfb]);
  const handleuser = () => {
    makeGetRequest(URLS.userroleget, null, null)
      .then((res) => {
        console.log("resuser", res)
        const result = res.data?.map(item => ({
          value: item?.id,
          label: item?.role_name
        }));
        setUser(result)
      })
      .catch((error) => {

      })
  }

  console.log("user",user)

  const getAccounts = () => {
    makePostRequest(URLS.getStripeAccount, null, {}, null)
      .then((res) => {
        console.log("res::::", res)
        const arr = [];
        res?.data?.map((val, index) => {
          if (val?.stripe_setup_done) {
            return arr.push({ value: val?.id, label: val?.email + "(payment verify)", id: val?.stripe_account_id })
          } else {
            return arr.push({ value: val?.id, label: val?.email, id: val?.stripe_account_id })
          }

        })
        setStripeData(arr)

      }).catch((error) => {
        console.log("error::", error)
      })
  }
  useEffect(() => {
    getUserType();
    handleuser();
    if (useractive == "") {
      navigate("/sign-in");
    }
    // getAccounts()
    makeGetRequest(URLS.timeZone, null, null, null)
      .then((res) => {
        setTimeZone(res.data)
      })
    handleshowupload();
    document.body.classList.add("parentragistrationform");
    return () => {
      document.body.classList.remove("parentragistrationform");
    };
  }, []);

  useEffect(() => {
    setchildno();
  }, [childList]);

  const options = {
    componentRestrictions: { country: ["US", "IND"] },
    fields: ["address_components", "geometry", "icon", "name"],
    //strictBounds: false,
    types: ["address"],
  };

  const getGoogleAddressStreetOne = () => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );
    autoCompleteRef.current.addListener("place_changed", async function () {
      const place = await autoCompleteRef.current.getPlace();

      // console.log(place, "place");
      let addressComponent = place?.address_components;
      let fullAddress = "";
      addressComponent.forEach((component) => {
        fullAddress += component.long_name + ", ";
      });
      fullAddress = fullAddress.slice(0, -2);

      setAddress(fullAddress);

      // console.log(fullAddress, "full address");

      // console.log(addressComponent, "address component");
      let obj = {
        country: "",
        city: "",
        zipcode: "",
        state: "",
        latitude: "",
        longitude: "",
      };
      obj.latitude = place.geometry.location.lat();
      obj.longitude = place.geometry.location.lng();

      for (let i = 0; i < addressComponent?.length; i++) {
        if (addressComponent[i].types[0] === "locality") {
          obj.city = addressComponent[i].long_name;
        }

        if (addressComponent[i].types[0] === "postal_code") {
          obj.zipcode = addressComponent[i].long_name;
        }

        if (addressComponent[i].types[0] === "administrative_area_level_1") {
          obj.state = addressComponent[i].long_name;
        }
        if (addressComponent[i].types[0] === "country") {
          obj.country = addressComponent[i].long_name;
        }
      }
      setZipcode(obj.zipcode);
      setLocationState(obj);
    });
  };

  useEffect(() => {
    if (!loading) {
      getGoogleAddressStreetOne();
    }
  }, [!loading]);

  const handleAddress = (e) => {
    setAddress(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
    setemailerror(false);
  };

  const handlePhone = (e) => {
    setPhone(e.target.value);
    setphoneerror(false);
  };
  const handleZipecode = (e) => {
    setZipcode(e.target.value);
    setziperror(false);
  };

  //activate stripe account api
  const stripeAccountActivation = () => {
    setLoading(true);
    let data = {
      id: userData.userAuth.id,
    };
    makePostRequest(URLS.stripeAccountActivation, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          setLoading(false);
          toastifyMessage(res.message, "success");
          window.location.href = res?.data?.url;
        } else if (res.code === 400) {
          setLoading(false);
          toastifyMessage(res.message, "error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (queryParams.acc) {
      navigate("/serve-dashboard");
    }
  }, [queryParams.acc]);

  const emailValidation = () => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (regex.test(email) === false) {
      setemailerror(true);
      setEmailErr("Please enter valid email");
      emref.current.focus();
      return false;
    }
    return true;
  };

  const phoneNumberValidation = () => {
    var regx = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    if (regx.test(phone) === false) {
      setphoneerror(true);
      setPhoneErr("Please enter valid phone no.");
      phref.current.focus();
      return false;
    }
    return true;
  };

  //== UI for user registration form//==

  return (
    <>
      {loading && (
        <div className="sharepopupOuter backgroundOpacity1">
          {/* <FourDotsLoader /> */}
        </div>
      )}
     
      <div className={`${status == true ? "col-lg-10" : "containerparent"}`}>
        {/* <div className={`${status == true ? " " : "col-lg-10 mx-auto"  }`}> */}
        <div className="Container-fluid" >
          <div className="profileStripSetings pt-5 ">
            {/* {status == true ? (
              <h2 className="profile">Profile Settings</h2>
            ) : (
              <>
                <h2 className="comunity">
                  Thank you for joining our<br></br> MatchED community.{" "}
                </h2>
                <h3 className="parent-form-para">
                  In order to personalize your experience, please provide us with
                  the optional information requested below.
                </h3>
                <span className="parent-text">
                  *Any profile information you share will be kept confidential and
                  will only be used to help customize your experience while using
                  MatchED's platform.
                </span>
                <h4 className="profile">Complete Your Profile</h4>
              </>
            )} */}
           
            <h3 className="nk-block-title page-title mt-3 mb-3 ms-5 pb-4 pt-5">Edit customer

        <button onClick={() => history.goBack()}  className="btn btn-outline-light bg-white d-none d-sm-inline-flex float-end btn-right">
				<em className="icon ni ni-arrow-left"></em><span>Back</span>
				</button>
        </h3>
            {/* <button
              className="strip-activation"
              onClick={stripeAccountActivation}
              Stripe
              Activation
            >
              Stripe Activation
            </button> */}
          </div>

         
              {/* {file != "" ? <img className="img-fluid" src={file} accept="image/png, image/jpeg" /> : <img className="img-fluid" src={User} />}
              <div className="input-file1">
                <div className="upload11" >upload profile image</div>
                <input type="file" className="file-handle" ref={imgref} onChange={handleChange} />
              </div> */}

              <Content>

      <Card className="card-bordered">
            <div className="form-container-parent">
              <div className="row p-3">
                <div className="col-lg-6">
                  <label className="form-label0">
                  <h5 className="form-h3">First Name<span className="start-color">*</span></h5>
                  </label>
                  <input
                    type="text"
                    className="form-control input-control rounded-pill"
                    id="input1"
                    ref={fref}
                    onChange={handlefn}
                    value={firstname}
                  />
                  {error && firstname == "" ? (
                    <span className="error1"> Please enter first name </span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-lg-6">
                  <label className="form-label0">
                  <h5 className="form-h3">Last Name<span className="start-color">*</span></h5>
                  </label>
                  <input
                    type="text"
                    className="form-control input-control rounded-pill"
                    id="input2"
                    ref={lref}
                    onChange={handleln}
                    value={lastname}
                  />
                  {error && lastname == "" ? (
                    <span className="error1"> Please enter last name </span>
                  ) : (
                    ""
                  )}

                </div>
              </div>
              <div className="row p-3">
                <div className="col-lg-6">
                  <label className="form-label0">
                  <h5 className="form-h3">Email<span className="start-color">*</span></h5>
                  </label>
                  <input
                    type="text"
                    className="form-control input-control rounded-pill"
                    id="inputemail"
                    ref={emref}
                    onChange={handleEmail}
                    value={email} 
                  />
                  {emailerror ? (
                    <span className="error1"> Please enter valid email. </span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-lg-6">
                  <label className="form-label0">
                  <h5 className="form-h3">Phone No.<span className="start-color">*</span></h5>
                  </label>
                  <input 
                    type="text"
                    className="form-control input-control rounded-pill"
                    id="inputphone"
                    ref={phref}
                    onChange={handlePhone}
                    value={phone} 
                  />
                  {phoneerror ? (
                    <span className="error1"> Please enter valid phone no. </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row p-3">
                <div className="col-lg-6">
                  <label className="form-label0">
                  <h5 className="form-h3">Address</h5>
                  </label>
                  <input
                    type="text"
                    name="address"
                    className="form-control input-control rounded-pill"
                    placeholder="Enter address"
                    value={address ? address : ""}
                    onChange={handleAddress}
                    ref={inputRef}
                  />
                </div>
                <div className="col-lg-6">
                  <label className="form-label0">
                  <h5 className="form-h3">Zip Code<span className="start-color">*</span></h5>
                  </label>
                  <input
                    type="text"
                    className="form-control input-control rounded-pill"
                    id="inputphone"
                    onChange={handleZipecode}
                    value={zipcode}
                    ref={zpref}
                  />
                  {ziperror && zipcode == null ? (
                    <span className="error1">Please enter valid zipcode </span>
                  ) : (
                    ""
                  )}
                  {ziperror && (zipcode?.length < 5 || zipcode?.length > 6) ? (
                    <span className="error1">Please enter valid zipcode </span>
                  ) : (
                    ""
                  )}
                </div>
             </div>
              <div className="row p-3">
                <div className="col-lg-6">
                  <label className="form-label2">
                  <h5 className="form-h3">Time Zone</h5>
                    {/* <span className="start-color">*</span> */}
                  </label>
                  <select
                    className="form-control"
                    onChange={(e) => setSelectTimeZone(e.target.value)} value={selectTimeZone}>
                    <option value="">Choose a time zone</option>
                    {timeZone?.map((temp) => (
                      <option value={temp.nameTimeZone}>{temp.nameTimeZone}</option>
                    ))}
                  </select>
                  {error && "" ? (
                    <span className="error-span controlinput">
                      Please enter your time zone
                    </span>
                  ) : (
                    ""
                  )}
                </div>
             
              <div className="col-lg-6">
              <label className="form-label2">
                  <h5 className="form-h3">User Type  (Select one or multiple)<span className="start-color">*</span></h5>
                    {/* <span className="start-color">*</span> */}
                  </label>
                  <Select  
                    value={userTypeData}
                    options={user}
                    isMulti
                    selectMultiple={true}
                    onChange={(e) => setUserTypeData(e)}
                    closeMenuOnSelect={false}
                    ref={userRef}
                  />
                  {(error && userTypeData.length <= 0) ? (
                    <span className="error-span controlinput">
                      Please choose user type.
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                </div>
             
              <div className="rows p-3">
                <div className="biotextareaouter">
                  <div className="radio-div1">
                    <h5 className="form-h3">About Yourself</h5>
                   
                      <div class="form-check form-check-inline p-0 ">
                        <textarea  
                          value={Bio}
                          onChange={(e) => setBio(e.target.value)}
                          rows={5}
                          cols={150}
                          style={{ width: "100%", border:"1px solid #dbdfea"}}
                          className="input-control p-1 m-1"
                          id="input1"
                        />
                      </div>
                    
                  </div>
                </div>
              </div>
              <div className="row p-3">
                <div className="col-lg-12 ">
                  <label className="form-label0 mt-2">
                  <h5 className="form-h3"> How many students are you creating profiles for?</h5>
                    {/* <span className="start-color">*</span> */}
                  </label>
                  <input
                    type="number" 
                    className="form-control"
                    id="input3"
                    // min=""
                    ref={scref}
                    onChange={handlesc}
                    value={selectchild}
                  />
                  {error && selectchild < 0 ? (
                    <span className="error1">
                      {" "}
                      Please enter valid student number{" "}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
         

            {inputlist && inputlist.length > 0 && 
              inputlist.map((val, index) => {
                return (
                  <>
                    <div className="first-child-detail-con">
                      <div className="row p-3">
                        <h4 className="first-child-h4">
                          {childdisplay[index]} student's name
                        </h4>
                        <div className="col-lg-6">
                          <label className="form-label2">
                            First Name
                            <span className="start-color">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control rounded-pill"
                            placeholder=""
                            id="input1"
                            name="firstName"
                            ref={childref}
                            onChange={(event) => handleInputChange(index, event)}
                            value={val.firstName}
                          />
                          {error && val.firstName == "" ? (
                            <span className="error1">
                              {" "}
                              Please enter first student name{" "}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-lg-6">
                          <label className="form-label2">Last Name<span className="start-color">*</span></label>
                          <input
                            type="text"
                            className="form-control rounded-pill"
                            placeholder=""
                            id="input2"
                            name="lastName"
                            ref={childrel}
                            onChange={(event) => handleInputChange(index, event)}
                            value={val.lastName}
                          />
                          {error && val.lastName == "" ? (
                            <span className="error1">
                              {" "}
                              Please enter lastname.{" "}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>{" "}
                    </div>
                  </>
                );
              })}
            <div className="row p-3">
              <div class="col-lg-12">
                <div className="f-con">
                  <h5 className="form-h3 mt-3">
                    What would your ideal homeschool schedule look like? <span className="select-one">
                      (select all that apply)
                    </span>{" "}
                  </h5>

                  <div>
                    <div class="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="inlineRadio111"
                        ref={hcref}
                        value="Half Day"
                        checked={homeschool.find(
                          (element) => element == "Half Day"
                        )}
                        onChange={handlehs}
                      />
                      <label
                        className="form-check-label  handle-radio"
                        htmlFor="inlineRadio111"
                      >
                        Half Day
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="inlineRadio222"
                        value="Full Day"
                        checked={homeschool.find(
                          (element) => element == "Full Day"
                        )}
                        onChange={handlehs}
                      />
                      <label
                        class="form-check-label  handle-radio"
                        htmlFor="inlineRadio222"
                      >
                        Full Day
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="inlineRadio333"
                        value="4 days week"
                        checked={homeschool.find(
                          (element) => element == "4 days week"
                        )}
                        onChange={handlehs}
                      />
                      <label
                        class="form-check-label  handle-radio"
                        htmlFor="inlineRadio333"
                      >
                        4 Days a Week
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="inlineRadio444"
                        value="5 days week"
                        checked={homeschool.find(
                          (element) => element == "5 days week"
                        )}
                        onChange={handlehs}
                      />
                      <label
                        class="form-check-label  handle-radio"
                        htmlFor="inlineRadio444"
                      >
                        5 Days a Week
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="inlineRadio555"
                        value="experimental"
                        checked={homeschool.find(
                          (element) => element == "experimental"
                        )}
                        onChange={handlehs}
                      />
                      <label
                        class="form-check-label  handle-radio"
                        htmlFor="inlineRadio555"
                      >
                        Experiential/Field Trip Based
                      </label>
                    </div>
                    {/* {error && homeschool?.length == 0 ? (
                    <span className="error1">
                      {" "}
                      Please select atleast one option from ideal homeschool
                      schedule{" "}
                    </span>
                  ) : (
                    ""
                  )} */}
                  </div>
                </div>
              </div>

              <div className="row p-3">
                <div class="col-lg-12">
                  <div className="radio-div">
                    <h5 className="form-h31">
                      Does your family have a faith background?{" "} <span className="select-one">(select one)</span>
                    </h5>
                    <div className="radio-btn-handle">
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="inlineRadioOptions1"
                          id="inlineRadio6"
                          checked={Firth == "No" ? true : false}
                          value="No"
                          onClick={fairthHandle}
                        />
                        <label
                          class="form-check-label handle-radio"
                          htmlFor="inlineRadio6"
                        >
                          No
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="inlineRadioOptions1"
                          id="inlineRadio66"
                          value="Yes"
                          checked={Firth == "Yes" ? true : false}
                          onClick={fairthHandle}
                        />
                        <label
                          class="form-check-label handle-radio"
                          htmlFor="inlineRadio66"
                        >
                          Yes
                        </label>
                      </div>
                      {/* {error && Firth == "" ? (
                      <span className="error1">
                        {" "}
                        Please select one option from faith background{" "}
                      </span>
                    ) : (
                      ""
                    )} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {Firth != "No" && showaddperson ? (
              <div className="container-2 mb-3">
                <div className="row p-3">
                  <div className="col-lg-12">
                    <label className="form-label11">
                      If yes, what is your faith affiliation? 
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="input3"
                      placeholder="Example: Lutheran, Catholic, Baptist, Nondenominational, etc."
                      ref={fbaref}
                      value={selectfa}
                      onChange={handlefa}
                    />
                  </div>
                </div>
                <div className="row p-3">
                  <div className="col-lg-12">
                    <h5 className="form-h32">
                      How important is it to you to integrate your family's faith
                      background into your student's education ?{" "}
                      <span className="select-one">(select one)</span>
                    </h5>

                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="inlineRadioOptions2"
                        id="inlineRadio777"
                        value="Not Important"
                        checked={selectffb == "Not Important"}
                        onChange={handleffb}
                      />
                      <label
                        class="form-check-label handle-radio"
                        htmlFor="inlineRadio777"
                      >
                        Not Important
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="inlineRadioOptions2"
                        id="inlineRadio888"
                        value="Somewhat Important"
                        checked={selectffb == "Somewhat Important"}
                        onChange={handleffb}
                      />
                      <label
                        class="form-check-label handle-radio"
                        htmlFor="inlineRadio888"
                      >
                        Somewhat Important
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="inlineRadioOptions2"
                        id="inlineRadio999"
                        value="Very Important"
                        checked={selectffb == "Very Important"}
                        onChange={handleffb}
                      />
                      <label
                        class="form-check-label handle-radio"
                        htmlFor="inlineRadio999"
                      >
                        Very Important
                      </label>
                    </div>
                    {/* {error && selectffb == "" ? (
                    <span className="error1">
                      Please select one option from family's faith background
                    </span>
                  ) : (
                    ""
                  )} */}
                  </div>
                </div>
              </div>
            ) : null}

            <div className="row p-3">
              <div className="col-lg-12">
                <div className="radio-div-3 mb-3">
                  <h5 className="form-h3">
                    How important is it to you to integrate character and values
                    into your student’s education?
                  </h5>
                  <div className="radio-btn-handle">
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="inlineRadioOptions3"
                        id="inlineRadio000"
                        ref={ceref}
                        value="Not important"
                        checked={selectce == "Not Important"}
                        onChange={handlece}
                      />
                      <label
                        class="form-check-label handle-radio"
                        htmlFor="inlineRadio000"
                      >
                        Not Important
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="inlineRadioOptions3"
                        id="inlineRadio321"
                        value="Somewhat important"
                        checked={selectce == "Somewhat Important"}
                        onChange={handlece}
                      />
                      <label
                        class="form-check-label handle-radio"
                        htmlFor="inlineRadio321"
                      >
                        Somewhat Important
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="inlineRadioOptions3"
                        id="inlineRadio432"
                        value="very important"
                        checked={selectce == "Very Important"}
                        onChange={handlece}
                      />
                      <label
                        class="form-check-label handle-radio"
                        htmlFor="inlineRadio432"
                      >
                        Very Important
                      </label>
                    </div>
                    {/* {error && selectce == "" ? (
                    <span className="error1">
                      {" "}
                      Please select one option child education
                    </span>
                  ) : (
                    ""
                  )} */}
                  </div>
                </div>
              </div>

              {userType.includes(2) ? (
                <div className="row p-3">
                  <div className="col-lg-12">
                    <div className="radio-div1">
                      <h5 className="form-h3">
                        Will you be a full-time homeschool teacher or do you have
                        an additional job? 
                      </h5>
                      <div className="radio-btn-handle">
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="inlineRadioOptions4"
                            ref={ajref}
                            id="inlineRadiooo"
                            value="Full time"
                            checked={selectaj == "Full time"}
                            onChange={handleaj}
                          />
                          <label
                            class="form-check-label handle-radio"
                            htmlFor="inlineRadiooo"
                          >
                            Full Time
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="inlineRadioOptions4"
                            id="inlineRadio2o"
                            value="Additional job"
                            checked={selectaj == "Additional job"}
                            onChange={handleaj}
                          />
                          <label
                            class="form-check-label handle-radio"
                            htmlFor="inlineRadio2o"
                          >
                            Working an Additional Job
                          </label>
                        </div>
                        {/* {error && selectaj == "" ? (
                        <span className="error1">
                          Please select one option additional job
                        </span>
                      ) : (
                        ""
                      )} */}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

               <div className="publice-btn-save-p" >
              <button className="save-button11" onClick={handlesavedata}>
               Update
              </button>
            </div>
            </div>
           
            </Card>
      </Content>
        </div>
      </div>
      
    </>
  );
};
export default EditCustomer;
